window.$ = window.jQuery = require('jquery');
const bootstrap = require('bootstrap');
const { getSessionStorage, setSessionStorage } = require('@webcp/util/storage');

require('util/extensions/wts-mousetrap');

const $_header = $('header'),
  $_nav = $('#nav'),
  $_navbar = $_nav.find('nav');

window.$header = $_header;
window.$nav = $_nav;
window.$navbar = $_navbar;

// Non-React Popovers.
[].slice
  .call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  .map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

// Always return true from confirm dialogs if the user prevents them from showing
window.nativeConfirm = window.confirm;
window.confirm = (message) => {
  const timeBefore = new Date();
  let confirmBool = window.nativeConfirm(message);
  const timeAfter = new Date();
  if (timeAfter - timeBefore < 350) {
    confirmBool = true;
  }
  return confirmBool;
};

// App version
$('#app-version').text(APP_VERSION);

/**
 * Current CSRF Token
 *
 * @returns {string | null}
 */
window.currentCsrfToken = () =>
  document.querySelector("meta[name='csrf-token']").getAttribute('content');

// Auto-hide notifications
setTimeout(function () {
  $('.notification-autohide').alert('close');
}, 9000);

if (DEBUG_REGIONS) {
  const enabled = getSessionStorage('app-session.debug-regions');

  const toggle = $(
    '<div class="form-check form-check-inline">' +
      '<input class="form-check-input" type="checkbox" id="regionDebugToggle" ' +
      (enabled ? 'checked="checked"' : '') +
      ' />' +
      '<label class="form-check-label col-form-label-sm" for="regionDebugToggle">' +
      'Regions' +
      '</label>' +
      '</div>'
  ).on('change', (e) => {
    setSessionStorage('app-session.debug-regions', e.target.checked);
    location.reload();
  });

  $('.region-debug-toggle').append(toggle);
}

//Fixes issue with old cached sort key when upgrading from 3047
//This is a temporary fix until all customers have upgraded to 3048 or higher.
if (
  localStorage.getItem('webcp_myQuotesSortByColumn') === '"QuoteNumberSortKey"'
) {
  localStorage.removeItem('webcp_myQuotesSortByColumn');
}
if (
  localStorage.getItem('webcp_myOrdersSortByColumn') === '"QuoteNumberSortKey"'
) {
  localStorage.removeItem('webcp_myOrdersSortByColumn');
}
if (
  localStorage.getItem('webcp_myPlansSortByColumn') === '"QuoteNumberSortKey"'
) {
  localStorage.removeItem('webcp_myPlansSortByColumn');
}
