/**
 * Overrides stopCallback so shortcuts work inside react-select
 */
/* global Mousetrap:true */
(function (Mousetrap) {
  const _originalStopCallback = Mousetrap.prototype.stopCallback;

  Mousetrap.prototype.stopCallback = function (e, element, combo, sequence) {
    if (
      element.parentElement &&
      (' ' + element.parentElement.className + ' ').indexOf(
        ' wts-react-select__input-container '
      ) > -1
    ) {
      return false;
    }

    return _originalStopCallback.call(self, e, element, combo);
  };
})(require('mousetrap'));
